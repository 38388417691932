/* eslint-disable react-hooks/exhaustive-deps */
import { useNavigate, useParams } from "react-router-dom";
import React, { useCallback, useEffect, useMemo, useState, FC } from "react";
import {
  DataGridPro,
  GridRowsProp,
  GridColDef,
  GridRenderCellParams,
  useGridApiRef,
  gridExpandedSortedRowIdsSelector,
  GridCellParams,
  GridRowSelectionModel,
  GridRowId,
  GridSortModel,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarDensitySelector,
  GridFilterModel,
} from "@mui/x-data-grid-pro";
import LoadingBackdrop from "../molecules/LoadingBackdrop";
import {
  Box,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip as MUITooltip,
  Typography,
  Button,
  Tooltip,
} from "@mui/material";
import ForecastTableRow from "../organisms/ForecastTableRow";
import ForecastStockMonthTableRow from "../organisms/ForecastStockMonthTableRow";
import { useApiGetWithCache } from "../../hooks/api/useApiGetWithCache";
import { useRecoilState } from "recoil";
import {
  FilteredDetails,
  FilteredDetailsState,
} from "../../stores/FiltererDetailsState";
import { SelectRow, SelectRowState } from "../../stores/SelectRowState";
import { useUpdateSuggestQty } from "../../hooks/api/useUpdateSuggestQty";
import { useUpdateOcfForecast } from "../../hooks/api/useUpdateOcfForecast";
import DetailFab from "../organisms/StockMovementDetail/DetailFab";
import {
  useMutateDetail,
  useSWRMutationDetail,
} from "../../hooks/api/mutate/useMutateDetail";
import HeaderAccordion from "../organisms/StockMovementDetail/HeaderAccordion";
import AirplaneIcon from "../atoms/AirplaneIcon";
import CheckIcon from "@mui/icons-material/Check";
import BoatIcon from "../atoms/BoatIcon";
import { KeyedMutator } from "swr";
import CalcAverage from "../../functions/CalcAverage";
import clsx from "clsx";
import {
  FilterDetailsGet,
  FilterDetailsSet,
} from "../../functions/storage/FilterDetails";
import {
  SelectRowStateSet,
  SelectRowStateGet,
} from "../../functions/storage/SelectRowState";
import {
  SortStateSet,
  SortStateGet,
  SortStateReset,
} from "../../functions/storage/SortParameters";
import {
  StmDetailKeysSet,
  StmDetailKeysGet,
} from "../../functions/storage/StmDetailKeys";
import {
  StmUpdatedDetailGet,
  StmUpdatedDetailReset,
} from "../../functions/storage/StmUpdatedDetail";
import { useApiPost } from "../../hooks/api/useApiPost";
import { RenderSelectFlagEditCell } from "../organisms/StockMovementDetail/RenderSelectFlagEditCell";
import CalcForecast from "../../../src/functions/CalcForecast";
import CalcLayerQty from "../../functions/CalcLayerQty";
import CalcPalletQty from "../../functions/CalcPalletQty";
import GridNumberFormatter from "../../functions/GridNumberFormatter";
import { UserState } from "../../stores/UserState";
import HasUpdateAuthority from "../../functions/HasUpdateAuthority";
import SortIcon from "@mui/icons-material/Sort";
import LineChartCell from "../molecules/LineChartCell";
import { CustomGridStringOperators } from "../atoms/CustomDataGridOperators";
import ErrorMessage from "../organisms/StockMovementDetail/ErrorMessage";
import { DetailDataGridSX } from "../organisms/StockMovementDetail/DetailDataGridSX";

const StockMovementDetail = () => {
  const { id } = useParams<Params>();
  const { data, loading, mutate } =
    useApiGetWithCache<Paths.GetStockMovementDetails.Responses.$200>(
      "stm",
      "stockmovement_details",
      {},
      id
    );
  const excelPrintInfo =
    useApiGetWithCache<Paths.GetCreateExcelProgress.Responses.$200>(
      "stm",
      "create_excel_progress",
      { swrOption: { refreshInterval: 3000 } }, // 3000ms間隔でリロード
      id || ""
    ).data;
  return loading ? (
    <LoadingBackdrop loading={loading} />
  ) : (
    <>
      {data && data.details ? (
        <StockMovementDetailsPresentation
          id={id!}
          data={data!}
          excelPrintData={excelPrintInfo}
          vendors={data.details
            .map((d) => d.vendor)
            .filter((v, i, a) => a.indexOf(v) === i)}
          mutate={mutate}
        />
      ) : (
        <>
          <ErrorMessage />
        </>
      )}
    </>
  );
};

type Props = {
  id: string;
  vendors: string[];
  data: Paths.GetStockMovementDetails.Responses.$200;
  excelPrintData: Paths.GetCreateExcelProgress.Responses.$200 | undefined;
  mutate: KeyedMutator<Paths.GetStockMovementDetails.Responses.$200>;
};

type Params = {
  id: string;
};

type SalesChart = {
  month: string;
  sales: number;
};

type salesAverageDifference = {
  sales_average_1: string;
  sales_average_2: string;
  ratio: number;
};

type field = { [key: string]: string };

// エクセル出力時に並び順設定およびフィルター可能なカラム
export const CanSortFilterColumns: field[] = [
  { vendor: "Vendor" },
  { item: "Item" },
  { status: "Status" },
  { item_description: "Description" },
  { note1: "Note1" },
  { note2: "Note2" },
  { note3: "Note3" },
  { moq: "MOQ" },
  { layer: "Layer" },
  { pallet: "Pallet" },
  { seasonality_code: "Seasonality" },
  { category: "Category" },
  { location: "Location" },
  { stock: "Stock" },
  { mtd_sales: "Mtd Sales" },
  { price: "Price" },
  { back_order: "BO" },
  { ocf_forecast1: "OCF1" },
  { ocf_forecast2: "OCF2" },
  { ocf_forecast3: "OCF3" },
  { ocf_forecast4: "OCF4" },
  { ocf_forecast5: "OCF5" },
  { suggest_air: "AIR Suggested Qty" },
  { suggest_sea: "SEA Suggested Qty" },
  { is_confirmed: "Checked" },
  { alternate: "Alternate" },
  { memo: "Memo" },
  { flag: "Flag" },
  { stdev_rate: "Stdev Rate" },
  { weight: "Weight" },
  // 計算フィールド
  { total: "Total" },
  { amt_air: "Amt Air" },
  { amt_sea: "Amt Sea" },
];

export const NotSortFilterColumns: field[] = [
  { av_12M: "Av12M" },
  { av_6M: "Av6M" },
  { av_3M: "Av3M" },
  { sales_diff: "Av Diff" },
  { stock_amount: "Stock Amt" },
  { is_apply_growth: "G.Rate" },
  { layer_suggested_qty: "Layer Sug." },
  { pallet_suggested_qty: "Pallet Sug." },
  { shortage_air: "Short Air" },
  { shortage_sea: "Short Sea" },
  { weight_total: "Air Weight" },
];

export const Operators: field[] = [
  //  For Filter`s Operator
  { contains: "contains" },
  { notContains: "not contains" },
  { equal: "equal" },
  { notEquals: "not equals" },
  { startsWith: "starts with" },
  { endsWith: "ends with" },
  { isEmpty: "is empty" },
  { isAnyOf: "is any of" },
  { isNotEmpty: "is not empty" },
  // For Sort`s Operator
  { asc: "ASC" },
  { desc: "DESC" },
];

// Excel Pint Validation Error Message
const RequiredErrMessage = "**Value is required**";
const SelectedErrMessage = "**Cannot be set when printing**";

const StockMovementDetailsPresentation: FC<Props> = (props) => {
  const apiRef = useGridApiRef();
  const navigate = useNavigate();
  const isOnlySea = !!props.data.header.only_sea;
  const [user] = useRecoilState(UserState);
  const hasUpdateAuth = HasUpdateAuthority(
    user?.is_user_update,
    user?.company_code
  );

  // setting for hide columns("false" is hide)
  const seaVisibilityColumns = {
    columns: {
      columnVisibilityModel: {
        weight: false,
        weight_total: false,
        sort_weight_total: false,
        suggest_air: false,
        shortage_air: false,
        amt_air: false,
        sort_amt_air: false,
      },
    },
  };

  // localStorage に値があればそちらを優先し表示可否を判断する
  const defaultVisibilityColumns = {
    columns: {
      columnVisibilityModel: {
        id: false,
        is_confirmed: false,
        vendor: true,
        flag: false,
        memo: false,
        item: true,
        item_description: true,
        status: true,
        note1: false,
        note2: false,
        note3: false,
        seasonality_code: false,
        category: false,
        location: false,
        weight: false,
        alternate: false,
        stdev_rate: false,
        sales: false,
        sales_chart: true,
        av_12M: false,
        av_6M: false,
        av_3M: false,
        sales_diff: false,
        stock: true,
        stock_amount: false,
        mtd_sales: false,
        back_order: true,
        suggest_air: true,
        suggest_sea: true,
        is_apply_growth: false,
        moq: false,
        layer_suggested_qty: false,
        pallet_suggested_qty: false,
        ocf_forecast1: false,
        ocf_forecast2: false,
        ocf_forecast3: false,
        ocf_forecast4: false,
        ocf_forecast5: false,
        shortage_air: false,
        shortage_sea: false,
        forecast: true,
        price: true,
        weight_total: false,
        amt_air: true,
        amt_sea: true,
        total: true,
      },
    },
  };

  const editableColumns = [
    "suggest_air",
    "suggest_sea",
    "ocf_forecast1",
    "ocf_forecast2",
    "ocf_forecast3",
    "ocf_forecast4",
    "ocf_forecast5",
    "memo",
    "flag",
  ];

  const getTogglableColumns = (columns: GridColDef[]) => {
    return columns
      .filter((column) => !column.field.startsWith("sort_"))
      .map((column) => column.field);
  };

  const sortingDetails = (
    target: Paths.GetStockMovementDetails.Responses.$200["details"],
    keys?: string[]
  ): Paths.GetStockMovementDetails.Responses.$200["details"] => {
    if (!keys?.length) {
      return target;
    }

    // Create a lookup object
    const targetLookup = target.reduce((lookup, detail) => {
      const key = `${detail.item}Y${detail.vendor}`;
      lookup[key] = detail;
      return lookup;
    }, {} as { [key: string]: (typeof target)[0] });

    // Use the lookup object to find each detail
    const details = keys.map((key) => targetLookup[key]).filter(Boolean);
    return details.map((detail, index) => ({ ...detail, id: index + 1 }));
  };

  const mutateDetail = useMutateDetail(props.id);
  const { trigger, data, isMutating } = useSWRMutationDetail(props.id);
  const [filteredDetails, setFilteredDetails] =
    useRecoilState(FilteredDetailsState);
  const [selectRowState] = useRecoilState(SelectRowState);
  const [sortLoading, setSortLoading] = useState(false);
  const [visibleRowsCount, setVisibleRowsCount] = useState(0);
  const [rowSelectionModel, setRowSelectionModel] =
    useState<GridRowSelectionModel>([]);
  const [hasUnFilterableColumn, setHasUnFilterableColumn] = useState(false);

  const rows: GridRowsProp = useMemo(() => {
    if (!isMutating) {
      setSortLoading(false);
      if (data) {
        const keys = data.details.map((v) => `${v.item}Y${v.vendor}`);
        StmDetailKeysSet(keys);
      }
    }

    return data
      ? data.details
      : sortingDetails(props.data.details, StmDetailKeysGet()!);
  }, [isMutating, data, props.data.details]);

  const salesAverageDifference: salesAverageDifference = useMemo(
    () => props.data.sales_average_difference,
    []
  );

  const forecastMonth: string[] = useMemo(() => props.data.month_headers, []);
  const columns: GridColDef[] = useMemo(() => {
    const colDef: GridColDef[] = createColumnDefinition(
      forecastMonth,
      isOnlySea,
      salesAverageDifference,
      !hasUpdateAuth
    );
    return colDef.map((col) =>
      col.type !== "number"
        ? {
            ...col,
            filterOperators: CustomGridStringOperators,
          }
        : col
    );
  }, [forecastMonth]);

  const updateSuggestQtyAsync = useUpdateSuggestQty(props.id);
  const updateOcfForecastAsync = useUpdateOcfForecast(props.id);
  const updateDetailField = useApiPost("detail", props.id);

  const saveFilterState = useCallback(() => {
    const filterDetails: FilteredDetails = {
      visibleRowId: gridExpandedSortedRowIdsSelector(
        apiRef.current.state,
        apiRef.current.instanceId
      ),
      gridState: apiRef.current.exportState(),
      scrollState: apiRef.current.getScrollPosition(),
      columnVisibility: apiRef.current.state.columns.columnVisibilityModel,
      columnOrder: apiRef.current.state.columns.orderedFields.map((v, i) => ({
        field: v,
        index: i,
      })),
      gridColumnWidth: Object.values(apiRef.current.state.columns.lookup)
        .filter((f: any) => !(f.width === 80 || f.width === 100))
        .map((m) => ({
          field: m.field,
          width: m.width || 100,
        })),
      gridDensity: apiRef.current.state.density,
    };
    // update local state
    setFilteredDetails(filterDetails);
    // update global state
    FilterDetailsSet(filterDetails);
  }, [apiRef, setFilteredDetails]);

  const inputOcfFormatter = (input: any) => {
    const ocf = input || 0;
    return ocf < 0 ? -1 : ocf;
  };

  const handleScroll = () => {
    if (filteredDetails.scrollState) {
      apiRef.current.scroll(filteredDetails.scrollState);
      setFilteredDetails({ ...filteredDetails, scrollState: undefined });
    }
  };

  const handleEditCommit = useCallback(
    (params: any, original: any) => {
      const mutateDetails = [...props.data.details];
      const detail = props.data.details.find(
        (f) => f.item + f.vendor === params.item + params.vendor
      );
      let fieldName = "none";
      for (const field of editableColumns) {
        if (params[field] !== original[field]) {
          fieldName = field;
          break;
        }
      }

      if (fieldName === "none") {
        return params;
      }
      let num = 0;
      if (fieldName === "suggest_air" || fieldName === "suggest_sea") {
        // If Updated Suggest Qty
        const body = {
          vendor: detail?.vendor,
          item: detail?.item,
        } as Paths.UpdateSuggestQty.Parameters.SuggestQty;

        if (fieldName === "suggest_air" || fieldName === "suggest_sea") {
          num = params[fieldName] || 0;
          body[fieldName === "suggest_air" ? "air" : "sea"] = num;
          mutateDetails.find(
            (f) => f.item + f.vendor === params.item + params.vendor
          )![fieldName] = num;
          updateSuggestQtyAsync(body).then(() => mutateDetail());
        }
      } else if (
        fieldName === "ocf_forecast1" ||
        fieldName === "ocf_forecast2" ||
        fieldName === "ocf_forecast3" ||
        fieldName === "ocf_forecast4" ||
        fieldName === "ocf_forecast5"
      ) {
        // If Updated Ocf
        const d = mutateDetails.find(
          (f) => f.item + f.vendor === params.item + params.vendor
        );
        if (d) {
          if (fieldName.startsWith("ocf_forecast")) {
            num = inputOcfFormatter(params[fieldName]);
            d[fieldName] = num;
            mutateDetails.find(
              (f) => f.item + f.vendor === params.item + params.vendor
            )![fieldName] = num;
          }
        }

        const body: Paths.UpdateOcfForecast.Parameters.OcfForecast = {
          vendor: detail?.vendor || "",
          item: detail?.item || "",
          field: fieldName,
          value: num,
        };

        updateOcfForecastAsync(body).then(() => mutateDetail());
      } else {
        const body: Paths.UpdateDetail.Parameters.UpdateField = {
          vendor: detail?.vendor || "",
          item: detail?.item || "",
          field: fieldName,
          value: params[fieldName] || "",
        };

        updateDetailField({ body });
      }
      props.mutate({ ...props.data, details: mutateDetails });
      return params;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [props.id]
  );

  const handleKeyDown = useCallback(async (e: KeyboardEvent) => {
    // Shift + Enterで詳細画面へ遷移
    if (e.shiftKey && e.key === "Enter") {
      const model = apiRef.current.getSelectedRows();
      saveFilterState();
      e.preventDefault();
      const id = Number(model.keys().next().value);
      const rowState: SelectRow = {
        rowId: id,
        field: SelectRowStateGet()!.field,
      };
      SelectRowStateSet(rowState);
      return id ? navigate(`item/${id}`) : {};
    }
  }, []);

  const iniFilterDetails = () => {
    const f: Paths.PostPrintExcel.Parameters.PrintExcelRequest["filterInfo"] =
      [];

    const filterModels = filteredDetails.gridState?.filter?.filterModel?.items;
    // If there is no filter in global state, return undefined
    if (filterModels === undefined) {
      return undefined;
    }

    filterModels.forEach((v) => {
      f.push({
        field: v.field,
        operator: v.operator,
        value: v.value,
      });
    });

    return f.length === 0 ? undefined : f;
  };

  const iniExcelRequest: Paths.PostPrintExcel.Parameters.PrintExcelRequest = {
    headerId: 0,
    companyCode: "",
    plannerCode: "",
    filterType: "",
    filterInfo: iniFilterDetails(),
    sortInfo: [],
  };

  const [excelRequest, setExcelRequest] = useState(iniExcelRequest);

  function isValueRequiredOperators(operator: string): boolean {
    return operator !== "isNotEmpty" && operator !== "isEmpty";
  }

  function convertRequiredValue(operator: string, value: string): string {
    if (isValueRequiredOperators(operator) && value === "") {
      value = RequiredErrMessage;
    }

    return value;
  }

  const handleFilterChange = useCallback((model: GridFilterModel) => {
    // modelはReadonly<GridFilterModel>のため
    const formattedModel = {
      ...model,

      // isAnyOfの時、valueが配列になるため、文字列に変換
      items: model.items.map((v) => ({
        ...v,
        value: Array.isArray(v.value)
          ? v.value.join(",")
          : v.value
          ? String(v.value)
          : "",
      })),
    };

    formattedModel.items = formattedModel.items.map((v, i) => {
      if (
        NotSortFilterColumns.map((key) => {
          return Object.keys(key)[0];
        }).includes(v.field || "")
      ) {
        v.operator = SelectedErrMessage;
        v.value = "";
      } else {
        // Required check
        v.value = convertRequiredValue(v.operator, v.value);
      }

      return v;
    });

    setExcelRequest((prevRequest) => ({
      ...prevRequest,
      filterType: formattedModel.logicOperator,
      filterInfo: formattedModel.items,
    }));
  }, []);

  const setPrintSortModel = (sortInfo: string) => {
    var info: Paths.PostPrintExcel.Parameters.PrintExcelRequest["sortInfo"] =
      [];
    if (!sortInfo || typeof sortInfo !== "string") {
      info = undefined;
    } else {
      info = sortInfo
        .split("-")
        .map((v) => {
          const [field, sort] = v.split("Y");
          return { field: field, operator: sort };
        })
        .filter((v) => v.field !== "");
    }

    if (!info) {
      return undefined;
    }

    return info.map((v) => {
      if (
        v.field &&
        NotSortFilterColumns.map((key) => {
          return Object.keys(key)[0];
        }).includes(v.field)
      ) {
        return {
          field: v.field,
          operator: SelectedErrMessage,
        };
      }
      return v;
    });
  };

  const handleSortModelChange = useCallback((sortModel: GridSortModel) => {
    if (sortModel.length === 0) {
      handleSortReset();
      return;
    }

    const newQueryParameters = sortModel
      .map((v) => `${v.field}Y${v.sort}`)
      .join("-");

    const prevQueryParameters = SortStateGet();
    if (prevQueryParameters === newQueryParameters) {
      return;
    }

    SortStateSet(newQueryParameters);
    setExcelRequest((prevRequest) => ({
      ...prevRequest,
      sortInfo: setPrintSortModel(newQueryParameters),
    }));
    setSortLoading(true);
    trigger({ queryStringParameters: { orders: newQueryParameters } });
  }, []);

  const handleSortReset = () => {
    const sortModel = apiRef.current.getSortModel();
    sortModel.forEach((v) => {
      apiRef.current.sortColumn(v.field, null);
    });
    SortStateReset();
    setSortLoading(true);
    trigger({});
    setExcelRequest((prevRequest) => ({
      ...prevRequest,
      sortInfo: [], // Reset
    }));
  };

  function validateRequiredValue(
    filterInfo: Paths.PostPrintExcel.Parameters.PrintExcelRequest["filterInfo"]
  ): boolean {
    if (!filterInfo) return false;
    const includeErrItem = filterInfo.map((v) => {
      if (
        v.value === RequiredErrMessage ||
        (isValueRequiredOperators(v.operator || "") && (v.value || "") === "")
      ) {
        return true;
      }
      return false;
    });

    return includeErrItem.includes(true);
  }

  useEffect(() => {
    const checkHasUnfilterable = (
      items:
        | Paths.PostPrintExcel.Parameters.PrintExcelRequest["sortInfo"]
        | Paths.PostPrintExcel.Parameters.PrintExcelRequest["filterInfo"]
    ) =>
      items?.some((item) =>
        NotSortFilterColumns.some((key) => Object.keys(key)[0] === item.field)
      );

    setHasUnFilterableColumn(
      checkHasUnfilterable(excelRequest.filterInfo || []) ||
        checkHasUnfilterable(excelRequest.sortInfo || []) ||
        validateRequiredValue(excelRequest.filterInfo || [])
    );
  }, [excelRequest]);

  useEffect(() => {
    window.addEventListener("keydown", handleKeyDown);
    // Check localStorage Filter Data
    const filter = FilterDetailsGet() || filteredDetails;

    // Have to delay 1 millisecond, because scroll method cannot be called.
    delay(1).then(() => {
      // Scroll
      handleScroll();

      // Set Width
      if (filter.gridColumnWidth) {
        filter.gridColumnWidth.forEach((f) => {
          if (f.field && f.width) {
            apiRef.current.setColumnWidth(f.field, f.width);
          }
        });
      }

      // Set Density
      filter.gridDensity && apiRef.current.setDensity(filter.gridDensity);

      // Set Visibility
      // Switch control is managed by hideable param
      let visibleModel = filter.columnVisibility;
      if (isOnlySea) {
        // Overwrite if onlySea flag true
        visibleModel = {
          ...(filter.columnVisibility ?? {}),
          ...seaVisibilityColumns.columns.columnVisibilityModel,
        };
      }
      visibleModel && apiRef.current.setColumnVisibilityModel(visibleModel);

      // Set Column Order
      filter.columnOrder?.forEach((f) =>
        apiRef.current.setColumnIndex(f.field, f.index)
      );

      const gridState = filter.gridState;
      if (gridState?.filter?.filterModel) {
        apiRef.current.setFilterModel(gridState.filter.filterModel);
      }
    });

    setFilteredDetails(filter);
    const rowState = SelectRowStateGet() || selectRowState;
    const id: GridRowId[] =
      Object.keys(rowState).length === 0 ? [1] : [rowState.rowId];
    apiRef.current.setRowSelectionModel(id);
    apiRef.current.setCellFocus(
      id[0],
      rowState.field ? rowState.field : "item"
    );
    // When transitioning from stockmovements, sortModel may remain, so clear it.
    const sorts = SortStateGet();
    const sortModel = apiRef.current.getSortModel();
    if (sorts?.length === undefined && sortModel.length !== 0) {
      sortModel.forEach((v) => {
        apiRef.current.sortColumn(v.field, null);
      });
    }
    if (StmDetailKeysGet()?.length === undefined) {
      const keys = props.data!.details.map((v) => v.item + "Y" + v.vendor);
      StmDetailKeysSet(keys);
    }
    // Delete any updates to ItemDetail that remain in LocalStorage.
    if (StmUpdatedDetailGet()) {
      StmUpdatedDetailReset();
    }

    if (filter.visibleRowId) {
      setVisibleRowsCount(filter.visibleRowId.length);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  const SortResetButton = () => (
    <>
      <Tooltip title={"Sort reset"}>
        <Button onClick={handleSortReset}>
          <SortIcon fontSize="small" />
          <Typography fontSize="small">sort reset</Typography>
        </Button>
      </Tooltip>
    </>
  );

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <SortResetButton />
        <GridToolbarDensitySelector />
      </GridToolbarContainer>
    );
  }

  return (
    <>
      <HeaderAccordion
        headerId={props.id}
        header={{ ...props.data.header }}
        excelPrint={props.excelPrintData}
        vendors={[...props.vendors]}
        purchaseOrders={[...props.data.purchase_orders]}
      />
      <Box style={{ height: "80vh", width: "100%" }} sx={DetailDataGridSX}>
        <DataGridPro
          style={{ paddingLeft: -5 }}
          density="compact"
          className="stm-list"
          rows={rows}
          columns={columns}
          loading={sortLoading}
          slots={{
            toolbar: CustomToolbar,
          }}
          slotProps={{
            toolbar: {
              columns: defaultVisibilityColumns.columns.columnVisibilityModel,
            },
            columnsManagement: {
              getTogglableColumns,
            },
            row: {
              onFocus: (e: any) => {
                setRowSelectionModel(e.currentTarget.getAttribute("data-id"));
              },
            },
            cell: {
              onFocus: (e: any) => {
                const id = SelectRowStateGet()?.rowId;
                const rowState: SelectRow = {
                  rowId: id ? id : 1,
                  field: e.currentTarget.getAttribute("data-field"),
                };
                SelectRowStateSet(rowState);
              },
            },
          }}
          sortingMode="server"
          rowHeight={200}
          hideFooterSelectedRowCount={true}
          disableMultipleRowSelection={true}
          rowSelectionModel={rowSelectionModel}
          apiRef={apiRef}
          processRowUpdate={(updatedRow, originalRow) =>
            handleEditCommit(updatedRow, originalRow)
          }
          initialState={filteredDetails.gridState ?? defaultVisibilityColumns}
          getRowClassName={(params) => {
            if (disconItemCheck(params.row)) {
              return "discon-row";
            } else if (params.row.is_confirmed) {
              return "checked-row";
            } else {
              return "default-row";
            }
          }}
          onSortModelChange={handleSortModelChange}
          onFilterModelChange={handleFilterChange}
          // If click suggest qty, do not move to item detail page. handleCellDoubleClick
          onCellDoubleClick={(params) => {
            if (!editableColumns.includes(params.field)) {
              saveFilterState();
              const rowState: SelectRow = {
                rowId: params.row.id,
                field: SelectRowStateGet()!.field,
              };
              SelectRowStateSet(rowState);
              navigate(`item/${params.row.id}`);
            }
          }}
          onRowSelectionModelChange={(newRowSelectionModel) => {
            setRowSelectionModel(newRowSelectionModel);
          }}
          onStateChange={(row) => {
            setVisibleRowsCount(row.pagination.rowCount);
          }}
        />
        {sortLoading && (
          <Box
            position="absolute"
            top={0}
            left={0}
            right={0}
            bottom={0}
            bgcolor="rgba(255, 255, 255, 0.2)"
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            {/* Loading... */}
          </Box>
        )}
      </Box>
      <DetailFab
        excelRequest={excelRequest}
        companyCode={user?.company_code || "MJ"}
        excelPrintStatus={props.excelPrintData?.print.print_excel_status || ""}
        excelPrintDate={props.excelPrintData?.print.update_at || ""}
        rowCount={visibleRowsCount}
        header={{
          headerId: props.data.header.id,
          plannerCode: props.data.header.planner_code,
        }}
        details={sortingDetails(props.data.details, StmDetailKeysGet()!).map(
          (v) => ({
            id: v.id,
            vendor: v.vendor,
            item: v.item,
            is_apply_growth: v.is_apply_growth,
          })
        )}
        saveFilter={saveFilterState}
        hasUnfilterableColumn={hasUnFilterableColumn}
      />
    </>
  );
};

const getOcfArray = (param: any) => {
  const ocfArray = [
    param.ocf_forecast1 || 0,
    param.ocf_forecast2 || 0,
    param.ocf_forecast3 || 0,
    param.ocf_forecast4 || 0,
    param.ocf_forecast5 || 0,
  ];

  return ocfArray;
};

const getForecast = (param: any, isOnlySea: boolean) => {
  const alternate = String(param.alternate) ?? "";
  const status = String(param.status) ?? "";
  return CalcForecast({
    isOnlySea: isOnlySea,
    bo: param.back_order || 0,
    mtdSales: param.mtd_sales || 0,
    stock: param.stock || 0,
    salesHistory: param.sales,
    ltmAIR: param.ltm_air,
    ltmSEA: param.ltm_sea,
    msmSea: param.msm_sea || 1,
    moq: param.moq || 1,
    ocfForecast: getOcfArray(param),
    isDiscon: alternate.includes("[To]") || status.includes("D"),
    layer: param.layer || 1,
    seasonalityCode: param.seasonality_code,
    // 画面上で更新可能な値
    suggestAIR: param.suggest_air,
    suggestSEA: param.suggest_sea,
    purchase: param.purchase_forecast,
    sales: param.sales_forecast,
  });
};

const disconItemCheck = (row: any) => {
  const alternate: string = row.alternate || "";
  return alternate.includes("[To]") || String(row.status).includes("D");
};

const createColumnDefinition = (
  forecastMonth: string[],
  isOnlySea: boolean,
  salesAverageDifference: salesAverageDifference,
  isEditable: boolean
): GridColDef[] => {
  return [
    {
      field: "id",
      headerName: "Line",
      headerAlign: "center",
      align: "center",
      width: 60,
      filterable: false,
      sortable: false,
    },
    {
      field: "is_confirmed",
      headerName: "Checked",
      headerAlign: "center",
      align: "center",
      width: 90,
      renderCell: (param) => (param.value ? <CheckIcon color="primary" /> : ""),
      filterable: false,
    },
    {
      field: "vendor",
      headerName: "Vendor",
      headerAlign: "center",
      align: "center",
      width: 80,
    },
    {
      field: "flag",
      headerName: "Flag",
      align: "center",
      width: 60,
      editable: isEditable,
      renderEditCell: RenderSelectFlagEditCell,
      cellClassName: (params: GridCellParams<any, number>) =>
        clsx("flag", (params.value || "").toString().toLowerCase()),
    },
    {
      field: "memo",
      headerName: "Memo",
      editable: isEditable,
      width: 150,
    },
    {
      field: "item",
      headerName: "Item",
      headerAlign: "left",
      align: "left",
      width: 100,
      cellClassName: "item-no",
    },
    {
      field: "item_description",
      headerName: "Description",
      headerAlign: "left",
      align: "left",
      width: 150,
      renderCell: renderDescription,
    },
    {
      field: "status",
      headerName: "Status",
      width: 80,
    },
    {
      field: "note1",
      headerName: "Note1",
      width: 70,
    },
    {
      field: "note2",
      headerName: "Note2",
      width: 80,
    },
    {
      field: "note3",
      headerName: "Note3",
      width: 80,
    },
    {
      field: "seasonality_code",
      headerName: "Seasonality",
      align: "center",
      width: 95,
    },
    {
      field: "category",
      headerName: "Category",
      align: "center",
      width: 85,
    },
    {
      field: "location",
      headerName: "Location",
      align: "center",
      width: 85,
    },
    {
      field: "weight",
      headerName: "Weight",
      headerAlign: "center",
      align: "center",
      width: 80,
      type: "number",
      hideable: !isOnlySea,
      valueFormatter: (value: number) => {
        return GridNumberFormatter(value);
      },
      valueGetter: (value, row): number => {
        let w = (row.weight || 0) * row.suggest_air;
        return w !== 0 ? w : 0;
      },
    },
    {
      field: "alternate",
      headerName: "Alternate",
      headerAlign: "center",
      align: "center",
      width: 120,
    },
    {
      field: "stdev_rate",
      headerName: "Stdev Ratio",
      headerAlign: "center",
      align: "center",
      width: 90,
      type: "number",
      valueFormatter: (value: number) => {
        return GridNumberFormatter(value);
      },
      valueGetter: (value): number | null =>
        value ? Number(value / 100) : null,
      renderHeader: () => (
        <MUITooltip
          title={
            "Multiplying factor that is calculated from standard deviation of sales histories."
          }
        >
          <>Stdev Ratio </>
        </MUITooltip>
      ),
    },
    {
      field: "sales",
      headerName: "Sales History",
      headerAlign: "center",
      align: "center",
      renderCell: (p) => renderSalesHistory(p, forecastMonth),
      minWidth: 360,
      sortable: false,
      filterable: false,
    },
    {
      field: "sales_chart",
      headerName: "Sales Chart",
      headerAlign: "center",
      align: "left",
      valueGetter: (value, row): SalesChart[] =>
        (row.sales as number[]).map((n, i) => ({
          month: forecastMonth[i],
          sales: n,
        })),
      renderCell: renderSalesHistoryChart,
      minWidth: 580,
      sortable: false,
      filterable: false,
    },
    {
      field: "av_12M",
      headerName: "Av12M",
      headerAlign: "center",
      align: "center",
      width: 75,
      type: "number",
      valueGetter: (value, row): string => CalcAverage(row.sales as number[]),
    },
    {
      field: "av_6M",
      headerName: "Av6M",
      headerAlign: "center",
      align: "center",
      width: 75,
      type: "number",
      valueGetter: (value, row): string =>
        CalcAverage((row.sales as number[]).slice(6)),
    },
    {
      field: "av_3M",
      headerName: "Av3M",
      headerAlign: "center",
      align: "center",
      width: 75,
      type: "number",
      valueGetter: (value, row): string =>
        CalcAverage((row.sales as number[]).slice(9)),
    },
    {
      field: "sales_diff",
      headerName: "Av Diff",
      sortable: false,
      width: 70,
      headerAlign: "center",
      align: "center",
      valueGetter: (value, row): string => {
        if (
          !(
            salesAverageDifference &&
            salesAverageDifference.sales_average_1 &&
            salesAverageDifference.sales_average_2 &&
            salesAverageDifference.ratio
          )
        ) {
          return "";
        }

        //2ヵ月のSalesAvを比較して、差分/大きいAvがRatio以上の場合、★を表示する
        const averageCalculate = (month: string) => {
          let n = 0;
          switch (month) {
            case "Av3M":
              n = 9;
              break;
            case "Av6M":
              n = 6;
              break;
            case "Av12M":
              n = 0;
          }
          return Number(CalcAverage((row.sales as number[]).slice(n)));
        };

        const salesAverageMonth1 = averageCalculate(
          salesAverageDifference.sales_average_1
        );
        const salesAverageMonth2 = averageCalculate(
          salesAverageDifference.sales_average_2
        );

        const big = Math.max(salesAverageMonth1, salesAverageMonth2);
        const small = Math.min(salesAverageMonth1, salesAverageMonth2);
        return (big - small) / big > salesAverageDifference.ratio / 100
          ? "★"
          : "";
      },
      renderCell: (params: GridRenderCellParams<any, string>) => (
        <Grid color="#008CA2">{params.value}</Grid>
      ),
    },
    {
      field: "stock",
      headerName: "Stock",
      align: "center",
      headerAlign: "center",
      width: 65,
      type: "number",
      valueGetter: (value): number => (value as number) ?? 0,
      renderCell: renderStock,
    },
    {
      field: "stock_amount",
      headerName: "Stock Amt",
      align: "center",
      headerAlign: "center",
      width: 90,
      type: "number",
      valueFormatter: (value: number) => {
        return GridNumberFormatter(value);
      },
      valueGetter: (value, row): number => {
        let stock = row.stock || 0;
        let price = row.price || 0;
        let amt = stock * price;
        return amt ? amt : 0;
      },
    },
    {
      field: "mtd_sales",
      headerName: "MTD Sales",
      width: 95,
      align: "center",
      headerAlign: "center",
      type: "number",
      valueGetter: (value): number => value ?? 0,
    },
    {
      field: "back_order",
      headerName: "BO",
      align: "center",
      headerAlign: "center",
      width: 50,
      type: "number",
      renderCell: renderBO,
      cellClassName: (params: GridCellParams<any, number>) =>
        clsx("back-order", {
          positive: (params.value as number) > 0,
        }),
    },
    {
      field: "suggest_air",
      headerName: "AIR Suggested Qty",
      width: 75,
      align: "center",
      headerAlign: "center",
      type: "number",
      editable: isEditable,
      hideable: !isOnlySea,
      renderCell: (p) => renderSuggestAIR(p),
      cellClassName: (params: GridCellParams<any, number>) =>
        clsx(
          params.row.suggest_air % params.row.moq === 0
            ? "suggest-air"
            : "no-moq-suggest-air",
          {
            positive: (params.value as number) > 0,
          }
        ),
      renderHeader: () => (
        <>
          <AirplaneIcon />
        </>
      ),
      preProcessEditCellProps: (params) => ({
        ...params.props,
        error:
          Number(params.props.value) !== 0 &&
          (Number(params.props.value) || -1) < 0,
      }),
    },
    {
      field: "suggest_sea",
      headerName: "SEA Suggested Qty",
      width: 75,
      align: "center",
      headerAlign: "center",
      type: "number",
      editable: isEditable,
      renderCell: (p) => renderSuggestSEA(p),
      cellClassName: (params: GridCellParams<any, number>) =>
        clsx(
          params.row.suggest_sea % params.row.moq === 0
            ? "suggest-sea"
            : "no-moq-suggest-sea",
          {
            positive: (params.value as number) > 0,
          }
        ),
      renderHeader: () => (
        <>
          <BoatIcon />
        </>
      ),
      preProcessEditCellProps: (params) => ({
        ...params.props,
        error:
          Number(params.props.value) !== 0 &&
          (Number(params.props.value) || -1) < 0,
      }),
    },
    {
      field: "is_apply_growth",
      headerName: "G.Rate",
      headerAlign: "center",
      align: "center",
      width: 60,
      valueGetter: (value, row) => (row.is_apply_growth ? "Y" : ""),
    },
    {
      field: "moq",
      headerName: "MOQ",
      headerAlign: "center",
      align: "center",
      type: "number",
      width: 45,
      valueFormatter: (value: number) => {
        return GridNumberFormatter(value);
      },
    },
    {
      field: "layer_suggested_qty",
      headerName: "Layer Sug.",
      headerClassName: "small",
      width: 80,
      align: "center",
      headerAlign: "center",
      type: "number",
      valueGetter: (value, row): number | string => {
        const layerResult = CalcLayerQty(row.suggest_sea, row.layer);
        return layerResult === 0 || row.layer <= 1 ? "" : layerResult;
      },
      renderCell: (p) => renderLayer(p),
      cellClassName: (params: GridCellParams<any, number>) =>
        clsx(
          CalcLayerQty(params.row.suggest_sea, params.row.layer) ===
            params.row.suggest_sea || !params.row.suggest_sea
            ? ""
            : "not-layer-qty",
          {
            positive: (params.value as number) > 0,
          }
        ),
    },
    {
      field: "pallet_suggested_qty",
      headerName: "Pallet Sug.",
      headerClassName: "small",
      width: 80,
      align: "center",
      headerAlign: "center",
      type: "number",
      valueGetter: (value, row): number | string => {
        const palletResult = CalcPalletQty(row.suggest_sea, row.pallet);
        return palletResult === 0 || row.pallet <= 1 ? "" : palletResult;
      },
      renderCell: (p) => renderLayer(p),
    },
    {
      field: "ocf_forecast1",
      headerName: "OCF1",
      width: 65,
      align: "center",
      headerAlign: "center",
      type: "number",
      editable: isEditable,
      renderCell: (p) => renderOcf(p),
      preProcessEditCellProps: (params) => ({
        ...params.props,
        error: disconItemCheck(params.row)
          ? (Number(params.props.value) || 0) < 0
          : (Number(params.props.value) || -1) < -1,
      }),
    },
    {
      field: "ocf_forecast2",
      headerName: "OCF2",
      width: 65,
      align: "center",
      headerAlign: "center",
      type: "number",
      editable: isEditable,
      renderCell: (p) => renderOcf(p),
      preProcessEditCellProps: (params) => ({
        ...params.props,
        error: disconItemCheck(params.row)
          ? (Number(params.props.value) || 0) < 0
          : (Number(params.props.value) || -1) < -1,
      }),
    },
    {
      field: "ocf_forecast3",
      headerName: "OCF3",
      width: 65,
      align: "center",
      headerAlign: "center",
      type: "number",
      editable: isEditable,
      renderCell: (p) => renderOcf(p),
      preProcessEditCellProps: (params) => ({
        ...params.props,
        error: disconItemCheck(params.row)
          ? (Number(params.props.value) || 0) < 0
          : (Number(params.props.value) || -1) < -1,
      }),
    },
    {
      field: "ocf_forecast4",
      headerName: "OCF4",
      width: 65,
      align: "center",
      headerAlign: "center",
      type: "number",
      editable: isEditable,
      renderCell: (p) => renderOcf(p),
      preProcessEditCellProps: (params) => ({
        ...params.props,
        error: disconItemCheck(params.row)
          ? (Number(params.props.value) || 0) < 0
          : (Number(params.props.value) || -1) < -1,
      }),
    },
    {
      field: "ocf_forecast5",
      headerName: "OCF5",
      width: 65,
      align: "center",
      headerAlign: "center",
      type: "number",
      editable: isEditable,
      renderCell: (p) => renderOcf(p),
      preProcessEditCellProps: (params) => ({
        ...params.props,
        error: disconItemCheck(params.row)
          ? (Number(params.props.value) || 0) < 0
          : (Number(params.props.value) || -1) < -1,
      }),
    },
    {
      field: "shortage_air",
      headerName: "Short AIR",
      headerAlign: "center",
      headerClassName: "small",
      align: "center",
      width: 75,
      sortable: false,
      hideable: !isOnlySea,
      valueGetter: (value, row): string => {
        const forecast = getForecast(row, isOnlySea);
        return Math.min(...forecast.month.slice(0, row.ltm_air + 1)) <
          row.msm_air
          ? "Y"
          : "";
      },
      renderCell: (params: GridRenderCellParams<any, string>) =>
        params.value === "Y" ? <Typography color="primary">★</Typography> : "",
    },
    {
      field: "shortage_sea",
      headerName: "Short SEA",
      headerClassName: "small",
      headerAlign: "center",
      align: "center",
      width: 75,
      sortable: false,
      valueGetter: (value, row): string => {
        const forecast = getForecast(row, isOnlySea);
        return Math.min(...forecast.month.slice(row.ltm_air, row.ltm_sea + 1)) <
          row.msm_sea
          ? "Y"
          : "";
      },
      renderCell: (params: GridRenderCellParams<any, string>) =>
        params.value === "Y" ? <Typography color="primary">★</Typography> : "",
    },
    {
      field: "forecast",
      headerName: "Forecast",
      headerAlign: "center",
      sortable: false,
      filterable: false,
      minWidth: 700,
      renderCell: (p) => renderForecast(p, forecastMonth, isOnlySea),
    },
    {
      field: "price",
      headerName: "Price",
      width: 70,
      align: "center",
      headerAlign: "center",
      type: "number",
      valueFormatter: (value: number) => {
        return GridNumberFormatter(value);
      },
      valueGetter: (value): number => {
        return value || 0;
      },
    },
    {
      field: "weight_total",
      headerName: "AIR Weight",
      width: 95,
      align: "center",
      headerAlign: "center",
      type: "number",
      hideable: !isOnlySea,
      valueFormatter: (value: number) => {
        return GridNumberFormatter(value);
      },
      valueGetter: (value, row): number => {
        return Number((row.weight || 0) * row.suggest_air);
      },
      renderHeader: () => (
        <>
          <AirplaneIcon />
          Weight
        </>
      ),
    },
    {
      field: "amt_air",
      headerName: "AIR Amt",
      width: 80,
      align: "center",
      headerAlign: "center",
      type: "number",
      hideable: !isOnlySea,
      valueFormatter: (value: number) => {
        return GridNumberFormatter(value);
      },
      valueGetter: (value, row): number => {
        return (row.price || 0) * row.suggest_air;
      },
      renderHeader: () => (
        <>
          <AirplaneIcon />
          Amt
        </>
      ),
    },
    {
      field: "amt_sea",
      headerName: "SEA Amt",
      width: 80,
      align: "center",
      headerAlign: "center",
      type: "number",
      valueFormatter: (value: number) => {
        return GridNumberFormatter(value);
      },
      valueGetter: (value, row): number => {
        return (row.price || 0) * row.suggest_sea;
      },
      renderHeader: () => (
        <>
          <BoatIcon />
          Amt
        </>
      ),
    },
    {
      field: "total",
      headerName: "Total",
      width: 110,
      align: "center",
      headerAlign: "center",
      type: "number",
      valueFormatter: (value: number) => {
        return GridNumberFormatter(value);
      },
      valueGetter: (value, row): number => {
        let airPrice = (row.price || 0) * row.suggest_air;
        let seaPrice = (row.price || 0) * row.suggest_sea;
        let totalPrice = airPrice + seaPrice;
        return totalPrice;
      },
    },
  ];
};

const renderSalesHistory = (
  params: GridRenderCellParams<any, number[]>,
  monthHeaders: string[],
  monthRange?: number
) => {
  const param = params.api.getRow(params.id!);
  return (
    <Table size="small" aria-label="mini table">
      <TableBody>
        <TableRow>
          {monthHeaders
            .slice(0, 12)
            .slice(-1 * (monthRange || 12))
            .map((m) => (
              <TableCell padding="none" align="center" key={m}>
                {m}
              </TableCell>
            ))}
        </TableRow>
        <TableRow>
          {param
            .sales!.slice(-1 * (monthRange || 12))
            .map((v: number, i: number) => (
              <TableCell
                padding="none"
                align="center"
                key={`tbl-${param.id}-${i}`}
              >
                {v.toLocaleString()}
              </TableCell>
            ))}
        </TableRow>
      </TableBody>
    </Table>
  );
};

const renderSalesHistoryChart = (
  params: GridRenderCellParams<any, SalesChart[]>
) => {
  const month =
    params.formattedValue?.map((v) => {
      return v.month;
    }) || [];
  const sales =
    params.formattedValue?.map((v) => {
      return v.sales;
    }) || [];

  return <LineChartCell month={month} qty={sales} />;
};

const renderDescription = (params: GridRenderCellParams<any, number>) => (
  <MUITooltip title={params.value}>
    <Grid>{params.value}</Grid>
  </MUITooltip>
);

const renderStock = (params: GridRenderCellParams<any, number>) => (
  <Grid fontWeight="bold" fontSize={18}>
    {params.value ? Number(params.value).toLocaleString() : 0}
  </Grid>
);

const renderBO = (params: GridRenderCellParams<any, number>) => (
  <Grid fontWeight="bold" fontSize={18}>
    {params.value ? Number(params.value).toLocaleString() : 0}
  </Grid>
);

const renderOcf = (params: GridRenderCellParams<any, string>) => {
  // -1 is null value
  let ocf = Number(params.value || 0);

  return (
    <Grid fontWeight="bold" fontSize={18}>
      {ocf < 0 ? null : Number(ocf).toLocaleString()}
    </Grid>
  );
};

const renderSuggestAIR = (params: GridRenderCellParams<any, number>) => {
  const tooltipNode = (
    <React.Fragment>
      <Typography variant="subtitle2">
        Stock:{" "}
        {params.row.stock ? Number(params.row.stock).toLocaleString() : 0}
      </Typography>
      <Typography variant="subtitle2">
        MTD Sales: {params.row.mtd_sales || 0}{" "}
      </Typography>
      <Typography variant="subtitle2">
        BO: {params.row.back_order || 0}
      </Typography>
      <Typography variant="subtitle2">
        MOQ: {params.row.moq ? Number(params.row.moq).toLocaleString() : 1}
      </Typography>
      <Typography variant="subtitle2">
        Seasonality: {params.row.seasonality_code || 6}
      </Typography>
      <br />
      <Typography variant="subtitle2" fontWeight="bold">
        AIR
      </Typography>
      <Typography variant="subtitle2">
        ・DMB:{" "}
        {params.row.dmb_air ? Number(params.row.dmb_air).toLocaleString() : 0}
      </Typography>
      <Typography variant="subtitle2">
        ・LTM: {params.row.ltm_air || 0}
      </Typography>
      <Typography variant="subtitle2">
        ・MSM: {params.row.msm_air || 0}
      </Typography>
    </React.Fragment>
  );
  return (
    <MUITooltip title={tooltipNode}>
      <Grid fontWeight="bold" fontSize={18}>
        {params.value ? Number(params.value).toLocaleString() : 0}
      </Grid>
    </MUITooltip>
  );
};

const renderSuggestSEA = (params: GridRenderCellParams<any, number>) => {
  const tooltipNode = (
    <>
      <Typography variant="subtitle2">
        Stock:{" "}
        {params.row.stock ? Number(params.row.stock).toLocaleString() : 0}
      </Typography>
      <Typography variant="subtitle2">
        MTD Sales:{" "}
        {params.row.mtd_sales
          ? Number(params.row.mtd_sales).toLocaleString()
          : 0}{" "}
      </Typography>
      <Typography variant="subtitle2">
        BO:{" "}
        {params.row.back_order
          ? Number(params.row.back_order).toLocaleString()
          : 0}
      </Typography>
      <Typography variant="subtitle2">
        MOQ: {params.row.moq ? Number(params.row.moq).toLocaleString() : 1}
      </Typography>
      <Typography variant="subtitle2">
        Seasonality: {params.row.seasonality_code}
      </Typography>
      <br />
      <Typography variant="subtitle2" fontWeight="bold">
        SEA
      </Typography>
      <Typography variant="subtitle2">
        ・DMB:{" "}
        {params.row.dmb_sea ? Number(params.row.dmb_sea).toLocaleString() : 0}
      </Typography>
      <Typography variant="subtitle2">
        ・LTM: {params.row.ltm_sea || 0}
      </Typography>
      <Typography variant="subtitle2">
        ・MSM: {params.row.msm_sea || 0}
      </Typography>
    </>
  );

  return (
    <MUITooltip title={tooltipNode}>
      <Grid fontWeight="bold" fontSize={18}>
        {params.value ? Number(params.value).toLocaleString() : 0}
      </Grid>
    </MUITooltip>
  );
};

const renderLayer = (params: GridRenderCellParams<any, number>) => {
  const tooltipNode = (
    <>
      <Typography variant="subtitle2">MOQ: {params.row.moq || 1}</Typography>
      {params.row.layer > 1 && (
        <Typography variant="subtitle2">Layer: {params.row.layer}</Typography>
      )}
      {params.row.pallet > 1 && (
        <Typography variant="subtitle2">Pallet: {params.row.pallet}</Typography>
      )}
    </>
  );

  return (
    <MUITooltip title={tooltipNode}>
      <Grid>{params.value}</Grid>
    </MUITooltip>
  );
};

const renderForecast = (
  params: GridRenderCellParams<any, string[]>,
  monthHeaders: string[],
  isOnlySea: boolean
) => {
  const forecast = getForecast(params.row, isOnlySea);
  const rowId = params.id as number;

  return (
    <Table padding="none" size="small" aria-label="mini table">
      <TableHead>
        <TableRow>
          <TableCell></TableCell>
          {monthHeaders.map((v) => (
            <TableCell align="center" key={v} style={{ fontWeight: "600" }}>
              {v}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {[
          { header: "Sales", values: params.row.sales_forecast },
          { header: "Purchase", values: params.row.purchase_forecast },
          {
            header: "Additional",
            values: forecast.additionalPurchase,
            ltm_air: params.row.ltm_air ?? 3,
            ltm_sea: params.row.ltm_sea ?? 6,
            isOnlySea,
          },
          { header: "Stock", values: forecast.stock },
        ].map((row, index) => (
          <ForecastTableRow
            key={index}
            rowId={rowId}
            rowHeader={row.header}
            values={row.values}
            ltm_air={row.ltm_air}
            ltm_sea={row.ltm_sea}
            isOnlySea={row.isOnlySea}
          />
        ))}
        <ForecastStockMonthTableRow
          rowId={rowId}
          values={forecast.month}
          minimumStockMonth={{
            air: params.row.msm_air,
            sea: params.row.msm_sea,
          }}
          leadTimeMonth={{
            air: params.row.ltm_air,
            sea: params.row.ltm_sea,
          }}
          isOnlySea={isOnlySea}
        />
      </TableBody>
    </Table>
  );
};

const delay = (time: number) =>
  new Promise((resolve) => setTimeout(resolve, time));

export default StockMovementDetail;
