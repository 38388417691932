/* eslint-disable */
// PRD環境インフラ情報
const awsmobile = {
  env: "PRD",
  aws_project_region: "sa-east-1",
  aws_cloud_logic_custom: [
    {
      name: "apie413e623",
      endpoint: "https://ssmccijwh2.execute-api.sa-east-1.amazonaws.com/prd",
      region: "sa-east-1",
    },
  ],
  aws_cognito_identity_pool_id:
    "sa-east-1:612771c4-845b-477e-aad5-bdf216049608",
  aws_cognito_region: "sa-east-1",
  aws_user_pools_id: "sa-east-1_HDnpurV0o",
  aws_user_pools_web_client_id: "3g9l0ct27p7sjvpneher8pahrh",
  oauth: {},
  aws_cognito_username_attributes: ["EMAIL"],
  aws_cognito_social_providers: [],
  aws_cognito_signup_attributes: ["EMAIL", "FAMILY_NAME", "GIVEN_NAME"],
  aws_cognito_mfa_configuration: "OPTIONAL",
  aws_cognito_mfa_types: ["TOTP"],
  aws_cognito_password_protection_settings: {
    passwordPolicyMinLength: 8,
    passwordPolicyCharacters: [],
  },
  aws_cognito_verification_mechanisms: ["EMAIL"],
  aws_user_files_s3_bucket: "stm-md-prd",
  aws_user_files_s3_bucket_region: "sa-east-1",
};

export default awsmobile;
